.cookieinfo {
    padding: 20px !important;
    display: flex;
    justify-content: center;
    align-items: stretch;
    border-radius: 1.5rem;
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);

    @include media-breakpoint-down(md) {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .cookieinfo-close {
        margin-left: 20px !important;
        padding: 20px !important;
        order: 2;
        border-radius: 0 !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
        background-color: $light !important;
        color: $primary !important;
        border-radius: 1.5rem !important;

        &:hover {
            background-color: $primary !important;
            color: $light !important;
        }
    }

    > span {
        order: 1;
    }
}

@include media-breakpoint-up(md) { 
    .cookieinfo {
        width: 80%;
        left: unset !important;
        right: 20px !important;
        bottom: 20px !important;
    }
 }

 @include media-breakpoint-up(xl) { 
    .cookieinfo {
        width: 40%;
    }
 }