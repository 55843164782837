/* source-sans-pro-regular - latin */
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/sourcesanspro/source-sans-pro-v19-latin-regular.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../fonts/sourcesanspro/source-sans-pro-v19-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../fonts/sourcesanspro/source-sans-pro-v19-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../fonts/sourcesanspro/source-sans-pro-v19-latin-regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("../fonts/sourcesanspro/source-sans-pro-v19-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../fonts/sourcesanspro/source-sans-pro-v19-latin-regular.svg#SourceSansPro")
            format("svg"); /* Legacy iOS */
}

/* dulcelin */
@font-face {
    font-family: "Dulcelin";
    font-style: normal;
    font-weight: 400;
    src: local(""),
        /* IE6-IE8 */ url("../fonts/dulcelin/Dulcelin.woff2") format("woff2"),
        /* Super Modern Browsers */ url("../fonts/dulcelin/Dulcelin.woff")
            format("woff");
}
