.site-header {
    border-bottom: 1px solid #a2b9a3;

    .logo {
        width: 100%;
        min-width: 200px;
        max-width: 200px;
        margin-bottom: -29px;

        @include media-breakpoint-up(sm) {
            min-width: 250px;
            max-width: 250px;
            margin-bottom: -34px;
        }

        @include media-breakpoint-up(xl) {
            min-width: 300px;
            max-width: 300px;
            margin-bottom: -40px;
        }
    }

    .navbar-toggler {
        margin-bottom: -20px;
        z-index: 1;
    }

    .navbar-nav {
        font-size: 20px;
    }

    @include media-breakpoint-up(xl) {
        .dropdown:hover .dropdown-menu {
            display: block;
        }
    }
}
