$spacer:      15px !default;
$text-color: black !default;
$text-color-correct: red !default;
$image-size: 50px !default;
$color-checked : $text-color !default;
$transition: .3s;

.fp-image-captcha {
    display: block;
    margin-top: $spacer;

    &__instructions {
        display: block;
        margin-bottom: $spacer;

        span {
            color: $text-color-correct;
            font-weight: 700;
        }
    }

    label {
        display: inline-block !important;
        width: $image-size + 10px !important;
        margin-right: $spacer;
    }

    input[type="radio"] {
        opacity: 0;
        width: 0;
        height: 0;
        margin: 0;

        &:checked + svg {
            border: 2px $color-checked solid;
            color: $color-checked;
        }
    }

    svg {
        width: $image-size;
        height: $image-size;
        padding: 5px;
        cursor: pointer;
        border: 2px solid transparent;
        position: relative;
        overflow: hidden;
        transition: $transition;
    }
}