body {
    font-family: "Source Sans Pro";
    color: $secondary;
}

.text-dulcelin {
    font-family: "Dulcelin";
}

.img-cover {
    object-fit: cover;
}

.vh-40 {
    height: 40vh;
}

.pointer {
    cursor: pointer;
}