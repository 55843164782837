@import "../../../vendor/feuerpanda/image-captcha/resources/scss/image-captcha";

.fp-image-captcha {

    label {
        position: relative;
    }

    input[type="radio"] {
        position: absolute;
        bottom: 0;
        left: calc(50% - 11.5px);
        width: unset;
        height: unset;
    }
}
