.site-footer {
    @include margin-top(10rem);

    .footer-menu {
        ul {
            li {
                display: inline !important;

                &:after {
                    content: "//";
                    margin-left: $spacer;
                    margin-right: $spacer;
                }

                &:last-of-type {
                    &:after {
                        content: "";
                    }
                }
            }
        }
    }
}
