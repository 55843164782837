.nav-tabs {
    .nav-link.active,
    .nav-item.show .nav-link {
        border-color: $primary $primary $light;
        background-color: $primary;
    }
}

.tab-content {
    background-color: $primary;
}
